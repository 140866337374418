import React, { useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import Microsoft  from '../assets/images/microsoft.svg'
import Ibm  from '../assets/images/ibm.svg'
import Staples  from '../assets/images/staples.svg'
import Blockbuster  from '../assets/images/blockbuster.svg'
import GameStop  from '../assets/images/gamestop.svg'
import PlayButton  from '../assets/images/play.svg'
import Testimonial  from '../assets/images/testimonial.png'
import YouTube from 'react-youtube';
import { Modal, ModalBody } from 'reactstrap'
import useDeviceDetect from '../hooks/useDeviceDetect'

const Styled = styled.div`
  background-color: #fff;
  padding-top:50px;
  @media (max-width: 1130px) {
    padding-left: 30px; 
    padding-right: 30px; 
  }
  .text {
    text-align: center;
    color: #999999;
    font-weight: 400;
    font-size: 16px;
    line-height: 52px;
  }
  .logos {
    width: 100%;
    max-width: 1080px;
  }
  .logo {
    width: 137px;
    margin: 20px;
    @media (max-width: 768px) {
      width: 73px;
      margin: 5px;
    }
  }
  .video {
    position: relative;
    margin-top: 80px;
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .play-btn {
      height: 90px;
      width: 90px;
      background: linear-gradient(139.65deg, #FFA47D 18.24%, #C25186 85.14%);
      border-radius: 100%;
      border: 7px solid #FFE9E0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s ease-in-out;
      :hover {
        transform: scale(1.1);
      }
      @media (max-width: 768px) {
        height: 50px;
        width: 50px;
        border-width: 4px;
        .play-icon {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
  .testimonial {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 130px;
    padding-bottom: 113px;
    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 50px 0px;
    }
    .image-box{
      display: flex;
      flex: 1;
      position: relative;
      height: 450px;
      @media (max-width: 768px) {
       height: 67vw;
        margin-bottom: 50px;
        img {
          width: 100%;
        }
      }
    }
    .meta {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 450px;
      position: relative;
 
      .divider {
        width: 408px;
        height: 6px;
        background: linear-gradient(102.72deg, #FFA47D 9.21%, #C25186 90.79%);
        margin: 30px 0px;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .review {
        font-style: italic;
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        @media (max-width: 768px) { 
          font-size: 18px;
        }
      }
      .title {
        font-weight: 800;
        font-size: 42px;
        line-height: 52px;
        @media (max-width: 768px) { 
          font-size: 32px;
        }
      }
      .author {
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: #999999;
      }
    }
  }
  
`;

const StyledModal = styled(Modal)`
  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    margin: 10px;
    border: none;
    background-color: transparent;
    z-index: 2;
  }
  .modal-content {
    background-color: transparent;
    border: none;
  }
  .modal-video-close-btn {
    position: relative;
    z-index: 2;
    top: -45px;
    right: 35px;
    display: block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
    float: right;
    @media (max-width: 768px) { 
      right: 20px;
      top: -10px;
    }
  }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background-color: #FFF;
    border-radius: 5px;
    margin-top: -6px;
  }
  .modal-video-close-btn:before {
    transform: rotate(45deg);
  }
  .modal-video-close-btn:after {
    transform: rotate(-45deg);
  }
  @media (max-width: 768px) { 
    .modal-content{
      width: 100vw;
    }
    .modal-body {
      padding: 0px;
    }
    .video-player{
      display: block !important;
    }
  }
`;

const Companies = () => {
  const [isOpen, setOpen] = useState(false)
  const close = () => setOpen(false);
  const detectMobile = useDeviceDetect();
  return (
    <Styled>
      <div className="content">
        <p className="text">Not used by these companies</p>
        <div className="logos d-flex align-items-center justify-content-around flex-wrap">
          <img className="logo" src={Microsoft} />
          <img className="logo" src={Ibm} />
          <img className="logo" src={Staples} />
          <img className="logo" src={Blockbuster} />
          <img className="logo" src={GameStop} />
        </div>
        <div className="video">
          <StaticImage src="../assets/images/video-preview.png" alt="Video preview" height={540}/>
          <div className="overlay">
            <button className="play-btn" onClick={()=> setOpen(true)}>
              <img src={PlayButton} className="play-icon" />
            </button>
          </div>
        </div>
        <div className="testimonial">
          <div className="image-box">
            <img src={Testimonial} />
          </div>
          <div className="meta">
            <p className="font-42 title">Forget slack, teams and everything else</p>
            <div className="divider" />
            <p className="review">“I can finally fax all my problems to my IT department! Thanks Faxgenie!”</p>
            <p className="author">- Michael Scott</p>
          </div>
        </div>
      </div>
      <StyledModal toggle={close} size="xl" isOpen={isOpen} centered className="modal">
        <ModalBody>
          <div className="">
            <div>
              <button onClick={close} class="modal-video-close-btn" aria-label="Close the modal by clicking here" />
            </div>
            <YouTube
              containerClassName="video-player d-flex justify-content-center"
              videoId="XcluAyZS_bQ"
              opts={{
                height: detectMobile.isMobile() ? 256: 600,
                width: typeof window !== `undefined` ?  window.innerWidth: 800,
                playerVars: {
                  autoplay: 1,
                },
              }}
            />
          </div>
        </ModalBody>
      </StyledModal>
    </Styled>
  )
}



export default Companies

import React from 'react';
import Link from './link'
import Logo from '../assets/images/faxlogo.svg'
import styled from 'styled-components'

let StyledNavbar = styled.div`
  max-width: 1226px;
  margin: 0 auto;
  padding: 0px !important;
  margin-top: 45px;
  @media (max-width: 1300px) { 
    margin-left: 30px;
  }
`

const Header = (props) => {
  return (
    <StyledNavbar>
      <Link to="/" className="navbar-brand">
        <img src={Logo} />
      </Link>
    </StyledNavbar>
  );
}

export default Header;

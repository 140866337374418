import React, { useEffect, useRef, useState } from 'react';
import {  Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import CloseIcon from '../assets/images/close.svg';
import ProgressBars from '../assets/images/progress-bars.svg';
import Keyboard from '../assets/images/keyboard.svg';
import axios from 'axios';
import faxSound from '../assets/Fax-machine-sound.mp3';

const StyledModal = styled(Modal)`
  .body{
   padding: 0px; 
  }
  hr {
    background-color: #DDDDDD;
  }
  .overlay {
    background-color:  rgba(0, 0, 0, 0.4);
  }
  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    margin: 10px;
    border: none;
    background-color: transparent;
    z-index: 2;
  }
  .fax-progress {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .status {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 12px;
    }
    .jokes {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #666666;
      margin-top: 12px;
    }
    .mprogress{
      position: relative;
      display: inline-block;
      padding: 3px;
      height: 20px;
      width: 276px;
      border-radius: 60px;
      border: 1px solid #666666;
      display: flex;
      justify-content:center;
      align-items: center;
      .moverlay {
        position: absolute;
        background-color: white;
        height: 14px;
        right:3px;
        left: 3px;
      }
    }
  }
  form {
    min-height:78px;
  }
  .button {
    background: linear-gradient(139.65deg, #FFA47D 18.24%, #C25186 85.14%);
    height: 54px;
    border: none;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 2;
    width: 180px;
  }
  .faxnumber-input{
    width: 300px;
    height: 54px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    outline: none;
    color: #000;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-right: 14px;
    background-color: #FFFFFF;
    padding: 16px;
    -webkit-appearance: none;
    ::placeholder{
      color: #999999;
      font-style: italic;
    }
  }
  
  .prize {
    max-width: 668px;
    display: flex;
    margin: 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 68px;
    .info {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      margin-bottom: 25px;
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 14px;
    }
    .keyboard {
      min-height: 103px;
      margin: 25px 0px;
    }
    .result, .error {
      display: block;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #219653;
      margin-top: 7px;
    }
    .error {
      color: red;
    }
  }
  @media (max-width: 768px) {
    .prize {
      padding-left: 30px;
      padding-right: 30px;
      .info {
        font-size: 16px;
        line-height: 22px;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 150px;
    }

    .faxnumber-input {
      width: 100%;
    }

    .button {
      width: 100%;
      margin-top: 15px;
    }
  }
  button:disabled {
    cursor: not-allowed;
  }
`;



const ModalExample = ({visible, close, faxNumber}) => {
  const [email, setEmail] = useState('');
  const [submmited, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState();
  const progressRef = useRef(null);
  progressRef.current = progress;

  const increment = () => {
    if(progressRef.current >= 11) {
      setSuccess(true);
      return;
    }
    setTimeout(() => {
      setProgress(progressRef.current + 1);
      increment();
    }, 1545)
  }

  const sendEmail = () => {
    const rawFaxNumber = faxNumber.replace(/\s+/g, '');
    axios.post(`https://api.chat-genie.com/faxes/${rawFaxNumber}/save-email`, {
        email
      }).then(res => {
        if(res.status === 200){
          setSubmitted(true);
        }
      }).catch(err => {
        setError('This email is already used!')
      })
  }
  
  useEffect(() => {
    const sound = new Audio(faxSound);
    sound.play();
    increment();
    return () => {
      sound.pause();
    }
  }, [])

  const onSubmitEmail = (event) => {
    event.preventDefault();
    sendEmail();
  }

  return (
    <div>
      <StyledModal centered size="lg" isOpen={visible} toggle={close} backdropClassName="overlay">
        <ModalBody className="body">
          <button onClick={close} className="close-btn float-right">
            <img src={CloseIcon} />
          </button>
          <div className="fax-progress">
            <p className="status">{success ? <strong>Fax sent!</strong> : <>Connecting to <strong>{faxNumber}...</strong></>}</p>
            <div className="mprogress">
              <img src={ProgressBars} width="270px" height="14px" />
              <div className="moverlay" style={{left: progress ?progress * 24.7 : 3}}/>
            </div>
            <p className={`jokes ${success ? 'invisible': 'visible'}`}>Feeding the fax gnomes... </p>
          </div>
          <hr />
          <div className="prize">
            <img src={Keyboard} className="keyboard" height="103" />
            <span className="title">Old is the new new</span>
            <p className="info">Enter your email for a chance to win a <strong>Das Keyboard 4 Ultimate Mechanical Keyboard</strong> with a value of <strong>$169</strong>. Because if the sound of the fax machine doesn’t excite you, the keyboard typing certainly will!</p>
           {!submmited && <form onSubmit={onSubmitEmail} className="d-flex justify-content-center">
              <div>
                <input value={email} onChange={ev => setEmail(ev.target.value)} type="email" className="faxnumber-input" placeholder="Enter your email" required />
                {
                // submmited ? <span className="result">Thanks! We’ll email you if you won!</span>
                  // : 
                  error ? <span className="error">{error}</span>
                  :null
                }
              </div>
              <button disabled={submmited} type="submit" className="button disabled">Send</button>
            </form>}
          </div>
        </ModalBody>
      </StyledModal>
    </div>
  );
}

export default ModalExample;
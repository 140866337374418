import React from 'react'
import styled from 'styled-components'
import FaxImage from '../assets/images/fax-colors.png'
import Papers from '../assets/images/papers.png'
import InputMask from 'react-input-mask';

const Styled  = styled.div`
  overflow: hidden;
  .title{
    max-width: 490px;
    z-index: 1;
  }
  .description {
    margin: 20px 0px;
    max-width: 450px;
  }
  
  .fax-logo {
    position: absolute;
    right: -200px;
    top: -30px;
    z-index: 1;
    @keyframes mymove{ 
      from {top:120px; right: -30px; opacity: 0.3;}
      to {top:180px; right: -80px; opacity: 1; }
    }
    .papers {
      position: absolute;
      top: 180px;
      right:-80px;
      animation:mymove 0.5s linear;
      transform: scale(1);
    }
  }
  .form {
    z-index: 6;
  }
  .hero {
    display: flex;
    align-items: center;
    height: 650px;
    @media (max-width: 1130px) {
      padding-left: 30px; 
      padding-right: 30px; 
    }
    @media (max-width: 900px) { 
      
      .fax-logo {
        right: -310px;  
      }
    }
  }

  @media (max-width: 768px) {
    .hero {
      flex-direction: column;
      height: auto;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }
    .fax-logo {
      position: relative;
      top:0;
      left:0;
      right:0;
      display: flex;
      width: 100%;
      justify-content: center;
      z-index: 1;
      margin-top: 30px;
      margin-bottom: 30px;
      min-height: 350px;
      img {
        width: 100%;
        height: 100%;
        transform: scale(1.4);
      }
      @keyframes mymove{ 
        from {top:0px; right: -20px; opacity: 0.3;}
        to {top:40px; right: -50px; opacity: 1; }
      }
      .papers {
        position: absolute;
        top: 40px;
        right:-50px;
        animation:mymove 0.5s linear;
        transform: scale(1);
      }
    }
    .form {
      padding: 30px;
      z-index: 6;
    }
    .faxnumber-input {
      width: 100%;
    }
    .button {
      width: 100%;
      margin-top: 15px;
    }
  }
`

const Hero = ({updateFaxnumber, onSubmit, error, faxnumber}) => {
  return (
    <Styled>
      <div className="content hero">
        <div className="form">
          <h3 className="font-52 title">Your tickets, instantly faxed.</h3>
          <p className="font-26 description">Faxgenie will change the way you communicate.</p>
          <div>
            <form onSubmit={onSubmit}>
              <div className="d-flex flex-column input-wrapper">
                <InputMask value={faxnumber} className="faxnumber-input" placeholder="Enter your fax number" required mask="+1\ 999 999 9999" maskChar={null}  onChange={updateFaxnumber} />
                {error && <span className="error">{error}</span>}
              </div>
              <button className="button" type="submit">Join the waitlist</button>
            </form>
          </div>
        </div>
        <div className="fax-logo">
          <img src={FaxImage}/>
          <img src={Papers} className="papers"/>
        </div>
      </div>
    </Styled>
  )
}

export default Hero

import React from 'react'
import styled from 'styled-components';
import Keyboard from '../assets/images/keyboard.svg';

const Styled = styled.div`
  height: 60px;
  background-color: #E0E2E7;
  z-index: 3;

  .text {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }

  .content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .keyboard {
    position: absolute;
    top: -25px;
    right: 0;
  }

  @media (max-width: 1150px) {
    height: auto;
    padding: 15px 5px;
    .content {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .keyboard {
      top: 0;
      margin-top: 10px;
      position: relative;
    }
  }
`;

const KeyboardStrip = () => {
  return (
    <Styled>
      <div className="content">
        <span className="text">Join the waitlist to win a Das Keyboard 4 Ultimate Mechanical Keyboard!</span>
        <div className="keyboard">
          <img
            src={Keyboard}
            height="80"
          />
        </div>
      </div>
    </Styled>
  )
}

export default KeyboardStrip

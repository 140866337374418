import React from 'react'
import styled from 'styled-components'
import Pro from '../assets/images/pro.svg'
import FaxPro from '../assets/images/fx-pro.svg'
import Fade from 'react-reveal/Fade';

const Styled = styled.div`
  position: relative;
  height: 836px;
  background-color: #1F1E25;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  overflow: hidden;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #fff;
  }
  span {
    font-style: italic;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }
  .fax-pro {
    position: absolute;
    bottom: -40px;
  }
  .title {
    color: #999999;
    font-weight: 600;
    margin-top: 87px;
  }
  .pro {
    margin-top: 50px;
  }
  @media (max-width: 768px) { 
    height: auto;
    overflow: hidden;
    span {
      font-size: 20px;
      line-height: 24px;
    }
    .title{
      margin-top: 53px;
    }
    .fax-pro{
      position: relative;
      bottom: 0;
      margin-top: 60px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img {
        height: 328px;
      }
    }
  }
`;

const Ad = () => {
  const onClick = (event) => {
    event.preventDefault();
    if(window){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  return (
    <Styled>
      <span className="title">Limited time offer</span>
      <span><a href="" onClick={onClick}>Sign up now</a> to get Faxgenie</span>
      <div className="pro">
        <img src={Pro}/>
      </div>
      <div className="fax-pro">
        <Fade bottom>
          <img src={FaxPro}/>
        </Fade>
      </div>
    </Styled>
  )
}

export default Ad

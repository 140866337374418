import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components'
import FaxPapers from '../assets/images/fax-papers.png'


const Styled = styled.div`
  background: #FAF9F6;
  height: 832px;
  .content2 {
    max-width: 1626px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    overflow: hidden;
    @media (max-width: 1130px) {
      padding: 0px 30px; 
      .left {
        margin-right: 35%;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 5;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 43px;
    letter-spacing: -1.46875px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
  .description {
    margin: 15px 0px;
    max-width: 420px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
  .steps {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 43px;
    color: #666666;
    margin-bottom: 25px;
    padding-left: 24px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .left {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    .fax-papers {
      position: absolute;
      top: -160px;
      left: auto;
    }
  }
  @media (max-width: 768px) {
    height: auto;
    .left {
      margin-right: 0px;
      .fax-papers {
        position: relative;
        top: 0%;
        width: 100%;
        transform: scale(1.5);
      }
    }
    .right {
      padding: 0px 30px;
      margin: 58px 0px;
    }
  }
`;

const HowItWorks = ({faxnumber, error, updateFaxnumber, onSubmit}) => {
  return (
    <Styled>
      <div className="content2">
        <div className="left">
          <img src={FaxPapers} className="fax-papers" />
        </div>
        <div className="right">
          <span className="title">How it works</span>
          <p className="description">Getting started with faxgenie is easy. Here are a few steps you might or might not want to follow to succeed.</p>
          <ol className="steps">
            <li>Link your fax number with faxgenie</li>
            <li>See all your tickets get faxed to you</li>
            <li>???</li>
            <li>Profit</li>
          </ol>
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-column input-wrapper">
              <ReactInputMask value={faxnumber} className="faxnumber-input" placeholder="Enter your fax number" required mask="+1\ 999 999 9999" maskChar={null} onChange={updateFaxnumber} />
              {error && <span className="error">{error}</span>}
            </div>
            <button className="button" type="submit">Join the waitlist</button>
          </form>
        </div>
      </div>
    </Styled>
  )
}

export default HowItWorks

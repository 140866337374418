import React from 'react'
import Link from './link';
import styled from "styled-components"
import ChatgenieWhiteLogo from '../assets/images/logo-white.svg';


const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #161616;
  max-height: 300px;
  @media (max-width: 768px) {
    padding: 40px 30px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
`;

const Logo = styled.a`
  display: inline-block;
  margin-bottom: 30px;

  svg {
    display: block;
  }
`;

const ListsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 100%;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  ul {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-height: 80px;
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1;
    li {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 19px;
      
    }

    a {
      text-decoration: none;
      color: #fff;
      transition: opacity .2s;

      &:hover,
      &:focus {
        opacity: .8;
      }
    }
  }
  @media (max-width: 900px) {
    ul {
      max-height: 110px;
    }
  }

  @media (max-width: 768px) {
    ul {
      min-width: 130px;
      max-height: 160px;
    }
  }

  @media (max-width: 360px) {
    ul {
      min-width: 150px;
    }
  }
`;

const navLinks = [
  {
    slug: 'mailto:help@chatgenie.io',
    name: 'Contact Us'
  },
  {
    slug: 'https://help.chatgenie.io/en/',
    name: 'Help Center'
  },
  {
    slug: 'https://feedback.chatgenie.io/',
    name: 'Feature Requests'
  },
  {
    slug: 'https://chatgenie.statuspage.io/',
    name: 'StatusPress'
  },
  {
    slug: 'https://www.chatgenie.io/terms-of-service',
    name: 'Terms of Service'
  },
  {
    slug: 'https://www.chatgenie.io/privacy-policy',
    name: 'Privacy Policy'
  },
  {
    slug: 'https://app.chat-genie.com/?screen=signUp',
    name: 'Try for free'
  },
  {
    slug: 'https://app.chat-genie.com/',
    name: 'Sign In'
  },
];


const Footer = () => (
  <Wrapper>
    <Content>
      <Logo href="/" rel="home" aria-current="page" title="Home">
        <img src={ChatgenieWhiteLogo} width="115" />
      </Logo>
      <Navigation>
        <ListsWrapper>
          <ul>
            {navLinks.map((link, index) => <li key={`link-${index}`}><Link to={link.slug}>{link.name}</Link></li>) }
          </ul>
        </ListsWrapper>
      </Navigation>
    </Content>
  </Wrapper>
)
          

export default Footer

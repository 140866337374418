import React from 'react'
import styled from 'styled-components'
import ChatgenieLeft from '../assets/images/left-chatgenie.svg'
import ChatgenieRight from '../assets/images/right-chatgenie.svg'

const Styled = styled.div`
  position:relative;
  height: 550px;
  background: #fff;
  .left-image {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .right-image {
    position: absolute;
    top: 0;
    right: 0;
  }
  .title {
    text-align: center;
    max-width: 514px;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1.3px;
  }
  .description {
    max-width: 619px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  a {
    margin-top: 30px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    width: 226px;
    height: 48px;
    background: #051275;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 48px;
  }
  @media (max-width: 1300px) { 
    height: auto;
    flex-direction: column-reverse;
    .right-image {
      display: none;
    }
    .left-image {
      position: relative;
      margin-top: 42px;
      height: 200px;
      overflow: hidden;
      img {
        height: 254px;
      }
    }
    .content {
      padding: 0px 30px;
    }
    .title{
      margin-top: 42px;
      font-size: 32px;
      line-height: 39px;
    }
    .description{
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

const Chatgenie = () => {
  return (
    <Styled>
      <div className="content">
        <p className="title">Luckily we have a better solution. Meet Chatgenie.</p>
        <p className="description">Try chatgenie today to start providing awesome support from anywhere. Get your tickets in Teams or Slack instantly and automate your process.</p>
        <a href="https://app.chat-genie.com/?screen=signUp" target="_blank">Try chatgenie for Free</a>
      </div>
      <div className="left-image">
        <img src={ChatgenieLeft} />
      </div>
      <div className="right-image">
        <img src={ChatgenieRight} />
      </div>
    </Styled>
  )
}

export default Chatgenie

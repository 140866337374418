import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'

import KeyboardStrip from '../components/KeyboardStrip'
import Companies from '../components/Companies'
import Hero from '../components/Hero'
import Chatgenie from '../components/Chatgenie'
import Ad from '../components/Ad'
import HowItWorks from '../components/HowItWorks'
import Modal from '../components/Modal'
import axios from 'axios'


const Styled = styled.div`
  .content {
    position: relative;
  }
  .button {
    background: linear-gradient(139.65deg, #FFA47D 18.24%, #C25186 85.14%);
    height: 54px;
    border: none;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 2;
    width: 180px;
  }
  .input-wrapper {
    width: 300px;
    margin-right: 14px;
  }
  .faxnumber-input{
    width: 100%;
    height: 54px;
    border: none;
    border-radius: 2px;
    outline: none;
    color: #000000;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    background-color: #FFFFFF;
    padding: 16px;
    ::placeholder{
      color: #999999;
      font-style: italic;
    }
  }
  .error {
    font-size: 14px;
    line-height: 17px;
    color: red;
    margin-top: 5px;
  }
  form {
    display: flex;
  }
  @media (max-width: 768px) {
    .input-wrapper {
      width: 100%;
      margin-right: 0px;
    }
    .button {
      width: 100%;
      margin-top: 15px;
    }
    form {
      flex-direction: column;
    }
  }
`;


let Index = () => {
  const [visible, setVisible] = useState(false);
  const [faxNumber, setFaxNumber] = useState('');
  const [error, setError] = useState();
  const openModal  = () => {
    if(faxNumber){
      setVisible(true)
      setFaxNumber(faxNumber);
    }
  };

  const sendFaxNumber = () => {
    if(faxNumber){
      axios.post(`https://api.chat-genie.com/faxes`, {
        phone: faxNumber.replace(/\s+/g, '')
      }).then(res => {
        if(res.status === 200){
          openModal(faxNumber);  
        }
      }).catch(err => {
        if(err.response.status === 400 && typeof err.response.data.data === 'string'){
          setError(err.response.data.data);
        } else if (err.response.status === 422 && typeof err.response.data.data === 'string') {
          setError(err.response.data.data)
        } else {
          setError('Oops, this fax number failed.')
        }
      }) 
    }
  }

  const updateFaxnumber = (event) => {
    const value = event.target.value
    setFaxNumber(value)
    setError(null);
  }

  const onSubmit = (event)=> {
    event.preventDefault();
    if(!error){
      if(faxNumber.replace(/\s+/g, '').length === 12){
        sendFaxNumber();
      } else {
        setError('Enter full number please!');
      }
    }
  }
  return (
    <Layout>
      <SEO title="Faxgenie - Your tickets, instantly faxed." description="Faxgenie is the ulitimate way for Managed Service Providers to interact with customers. Get all your tickets faxed in an instant. Join the re-evolution!" />
      <Styled>
        <Hero
          openModal={openModal}
          updateFaxnumber={updateFaxnumber}
          onSubmit={onSubmit}
          faxnumber={faxNumber}
          error={error}
        />
        <KeyboardStrip />
        <Companies />
        <HowItWorks
          openModal={openModal}
          updateFaxnumber={updateFaxnumber}
          onSubmit={onSubmit}
          faxnumber={faxNumber}
          error={error}
        />
        <Ad />
        <Chatgenie />
      </Styled>
      {visible && <Modal faxNumber={faxNumber} visible={visible} close={() => setVisible(false)} />}
    </Layout>
  )
}

export default Index
